<template>
  <div class="circle">
    <a href="#" id="openFormLink" @click.prevent="openModal">
      <img src="../assets/Contactus.svg" alt="Contact Us" width="70" class="help">
    </a>
    <div id="formModal" class="modal" v-if="showModal" @click.self="closeModal">
      <div class="modal-content">
        <span class="closeBtn" @click="closeModal">CLOSE &times;</span>
        <form @submit.prevent="handleSubmit">
          <div class="form-control">
            <input type="text" id="name" v-model="form.name" required>
            <label>
              <span v-for="(char, index) in 'Name'" :key="index" :style="{ transitionDelay: `${index * 50}ms` }">{{ char }}</span>
            </label>
          </div>
          <div class="form-control">
            <input type="email" id="email" v-model="form.email" required>
            <label>
              <span v-for="(char, index) in 'Email'" :key="index" :style="{ transitionDelay: `${index * 50}ms` }">{{ char }}</span>
            </label>
          </div>
          <div class="form-control">
            <input type="tel" id="phone" v-model="form.phone" required>
            <label>
              <span v-for="(char, index) in 'Phone'" :key="index" :style="{ transitionDelay: `${index * 50}ms` }">{{ char }}</span>
            </label>
          </div>
          <div class="form-control">
            <input type="text" id="message" v-model="form.message" required>
            <label>
              <span v-for="(char, index) in 'purpose of message'" :key="index" :style="{ transitionDelay: `${index * 50}ms` }">{{ char }}</span>
            </label>
          </div>
          <div class="button-group">
            <button @click.prevent="sendWhatsApp" id="whatsapp">
              <img src="../assets/whatsapp.svg" alt="WhatsApp" width="20">
            </button>
            <button @click.prevent="sendGmail" id="gmail">
              <img src="../assets/gmail.svg" alt="Gmail" width="20">
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      form: {
        name: '',
        email: '',
        phone: '',
        message: ''
      }
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    handleSubmit() {
      console.log('Form submitted:', this.form);
      this.closeModal();
    },
    sendWhatsApp() {
      const { name, email, message } = this.form;
      const whatsappNumber = "+919964416826"; // Replace with your number
      const whatsappURL = `https://wa.me/${whatsappNumber}/?text=`;
      const whatsappMessage = `${encodeURIComponent(name)}%0A${encodeURIComponent(email)}%0A${encodeURIComponent(message)}`;
      const finalURL = whatsappURL + whatsappMessage;
      window.open(finalURL, '_blank');
      this.closeModal();
    },
    sendGmail() {
      const { name, phone, message } = this.form;
      const recipientmail = "marketing@lgcengg.in";
      const ccEmail = "marketing@lgcengg.com";
      const gmailURL = "https://mail.google.com/mail/?view=cm&fs=1&to=" + recipientmail + "&cc=" + ccEmail + "&su=Contact&body=";
      const gmailMessage = "Name: " + encodeURIComponent(name) + "%0APhone: " + encodeURIComponent(phone) + "%0A" + encodeURIComponent(message);
      const finalGmailURL = gmailURL + gmailMessage;
      window.open(finalGmailURL, '_blank');
      this.closeModal();
    }
  }
};
</script>

<style scoped>
.circle .help {
  position: fixed;
  right: 1%;
  bottom: 1%;
  opacity: 60%;
  z-index: 1100; /* Ensure it's above the navbar */
  cursor: pointer;
}

.circle .help:hover {
  opacity: 100%;
  transform: scale(1.1);
  transition: .3s;
}

.circle a {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1100; /* Ensure it's above the navbar */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 350px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
  animation: slideIn 0.5s forwards;
}

.closeBtn {
  color: #aaa;
  display: flex;
  justify-content: flex-end;
  font-size: 28px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
}

.closeBtn:hover,
.closeBtn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

button {
  padding: 10px;
  background-color: #FFD400;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 10px 0 0;
}

.form-control {
  position: relative;
  margin: 20px 0 40px;
  width: 190px;
  border: none;
  font-family: cursive, Geneva, Tahoma, sans-serif;
  letter-spacing: -2px;
}

.form-control input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px red solid;
  display: block;
  width: 150%;
  padding: 15px 0;
  font-size: 18px;
  color: black;
}

.form-control input:focus,
.form-control input:valid {
  outline: 0;
  border-bottom-color: lightblue;
}

.form-control label {
  position: absolute;
  top: 15px;
  left: 0;
  pointer-events: none;
}

.form-control label span {
  display: inline-block;
  font-size: 18px;
  min-width: 5px;
  color: red;
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-control input:focus + label span,
.form-control input:valid + label span {
  color: lightblue;
  transform: translateY(-30px);
}

.button-group {
  display: flex;
  justify-content: space-between;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
