import { createRouter, createWebHistory } from 'vue-router';
import About from '../views/About.vue';
import CareersPage from '../views/CareersPage.vue';
import CertificationPage from '../views/Certification.vue';
import CsrView from '../views/CsrView.vue';
import FacilitiesSection from '../views/FacilitiesSection.vue';
import GalleryPage from '../views/GalleryPage.vue';
import Home from '../views/Home.vue';
// import Investors from '../views/Investors.vue';
import MaterialsViewPage from '../views/MaterialsView.vue'; // Ensure this import is correct
import PoliciesPage from '../views/PoliciesPage.vue';
import OurInvestors from '@/components/OurInvestors.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/investors',
    name: 'Investors',
    component: OurInvestors
  },
  {
    path: '/facilitiesSection',
    name: 'FacilitiesSection',
    component: FacilitiesSection
  },
  {
    path: '/csr',
    name: 'CsrView',
    component: CsrView
  },
  {
    path: '/certification',
    name: 'CertificationPage',
    component: CertificationPage
  },
  {
    path: '/gallery',
    name: 'GalleryPage',
    component: GalleryPage
  },
  {
    path: '/careers',
    name: 'CareersPage',
    component: CareersPage
  },
  {
    path: '/policies',
    name: 'PoliciesPage',
    component: PoliciesPage
  },
  {
    path: '/materials',
    name: 'MaterialsView',
    component: MaterialsViewPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
