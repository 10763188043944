<template>
  <div class="investors-page">
    <div class="container-fluid">
      <div class="row">
        <!-- Vertical Tabs -->
        <div class="col-md-3 sidebar">
          <div class="nav flex-column nav-pills" role="tablist">
            <button 
              class="nav-link" 
              :class="{ active: activeTab === 'IPO' }"
              data-bs-toggle="pill" 
              data-bs-target="#IPO" 
              role="tab"
              aria-selected="true"
            >
              IPO
            </button>
            <button 
              class="nav-link" 
              :class="{ active: activeTab === 'soon' }"
              data-bs-toggle="pill" 
              data-bs-target="#soon" 
              role="tab"
              aria-selected="false"
            >
              More Coming Soon
            </button>
          </div>
        </div>

        <!-- Tab Content -->
        <div class="col-md-9 main-content">
          <div class="tab-content">
            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'IPO' }" id="IPO" role="tabpanel">
              <div class="doc-containers">
                <h3>DP</h3>
                <button @click="downloasoonDF('/blank.pdf')">Download File</button>
              </div>
            </div>
            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'soon' }" id="soon" role="tabpanel">
              <!-- <div class="doc-containers">
                <h3>soon</h3>
                <button @click="downloasoonDF('/blank.pdf')">Download File</button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvestorsPage',
  data() {
    return {
      activeTab: 'IPO' // Default tab
    };
  },
  methods: {
    downloasoonDF(filePath) {
      window.open(filePath, '_blank');
    },
    updateActiveTabFromHash() {
      const hash = window.location.hash.substring(1); // Remove the '#'
      const availableTabs = ['IPO', 'soon']; // Available tab names

      if (availableTabs.includes(hash)) {
        this.activeTab = hash;
      } else {
        this.activeTab = 'IPO'; // Default tab if hash is invalid or missing
      }
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    // Set tab based on initial hash
    this.updateActiveTabFromHash();

    // Listen for hash changes
    window.addEventListener('hashchange', this.updateActiveTabFromHash);

    if (!window.location.hash) {
      window.location.hash = 'IPO'; // Set default hash
    }

    // Update hash when clicking tabs (prevents scroll behavior)
    document.querySelectorAll('[data-bs-toggle="pill"]').forEach(link => {
      link.addEventListener('click', (event) => {
        event.preventDefault(); // Prevent scrolling

        const target = event.target.getAttribute('data-bs-target').substring(1);
        if (window.location.hash !== `#${target}`) {
          window.history.replaceState(null, null, `#${target}`);
          this.activeTab = target;
        }
      });
    });
  },
  beforeUnmount() {
    window.removeEventListener('hashchange', this.updateActiveTabFromHash);
  }
};
</script>

<style scoped>
.investors-page {
  background-color: var(--custom-bg-tertiary);
  min-height: 100vh;
}

.sidebar {
  background-color: var(--custom-bg-secondary);
  min-height: 100vh;
  padding: 20px;
}

.nav-link {
  color: white;
  padding: 15px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-link:hover {
  background-color: var(--custom-bg-primary);
  color: var(--custom-text-primary);
}

.nav-link.active {
  background-color: #ffee01 !important;
  color: var(--custom-text-primary) !important;
}

.main-content {
  padding: 30px;
}

.tab-pane {
  padding: 20px;
}

button {
  border-radius: 5px;
  background-color: var(--custom-bg-primary);
  color: var(--custom-bg-secondary);
  border: none;
  padding: 0.5rem;
}

.sidebar button {
  margin: 1rem 0;
}

.doc-containers {
  margin-top: 1rem;
  padding: 1rem;
  border-bottom: 2px solid rgb(197, 192, 192);
}

@media (max-width: 765px) {
  .sidebar {
    min-height: 10vh;
  }
}
</style>
